
* {
	box-sizing: border-box;
}

body {
  margin: 0;
	width: 100vw;
	max-width: 100vw;
	font-size: 14px;
	background: rgba(255, 255, 255, 1);
	overflow-x: hidden;
}
