.wrapper {
	width: calc(100%);
  padding: 32px 32px 60px;
	opacity: 1;
	position: relative;
	overflow-x: hidden;
}

.header {
	width: 100%;
	padding: 0px 0 32px;
}

a {
  text-decoration: none;
}

.header > div {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	max-width: 1100px;
	margin: 0 auto 0;
}

.header > div > .logo {
	height: 70px;
}

@media(max-width: 600px) {
	.header {
		padding: 0px 0 12px;
	}

	.header > div {
		justify-content: center;
	}

	.header > div > a {
		display: none;
	}

	.header > div > .logo {
		height: 50px;
	}
}



.section-1-wrapper {
	width: 100%;
	opacity: 1;

  display: flex;
  flex-direction: column;
	align-items: center;
}


.title-span {
	width: 100%;
	color: rgba(34, 3, 79, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: bolder;
	font-size: 52px;
	opacity: 1;
	text-align: center;
	max-width: 800px;
	margin-bottom: 20px;
}



.people-img {
	max-width: 558px;
	width: 100%;
	object-fit: contain;
}

.subtitle-span {
	color: rgba(49, 54, 57, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: Regular 6;
	font-size: 24px;
	text-align: center;
	max-width: 800px;
	margin-bottom: 40px;
}

@media(max-width: 700px) {
	.title-span {
		font-size: 30px;
	}
	.subtitle-span {

		font-size: 18px;
	}

	br.desktop {
		display: none;
	}
}

.waitlist-button {
	background: rgba(60, 63, 183, 1);
	padding: 16px 24px;
	opacity: 1;
	top: 0px;
	left: 0px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px rgba(0.13333334028720856, 0.0117647061124444, 0.30980393290519714, 0.15000000596046448);

  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-family: HouschkaRoundedAlt;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.waitlist-button:hover {
	transform: scale(1.01);
}

.waitlist-button:active {
	transform: scale(0.99);
}

.waitlist-button.big {
  font-size: 24px;
	padding: 24px 36px;
}

.section-1-wrapper > a {
	margin-top: -100px;
}



@media(max-width: 500px) {
	.waitlist-button.big {

		padding: 16px 24px;
	  font-size: 18px;
	}

	.section-1-wrapper > a {
		margin-top: -40px;
	}
}


	/* background: linear-gradient(rgba(249, 180, 167, 0.7400000095367432), rgba(246, 161, 251, 0.44999998807907104)); */

.section-2-wrapper {
	width: 100%;
	opacity: 1;
	margin-top: 120px;

  display: flex;
  flex-direction: column;
	align-items: center;
}

.title-2-span {
	color: rgba(34, 3, 79, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: bold;
	font-size: 42px;
	opacity: 1;
	text-align: center;
}

@media(max-width: 500px) {
	.title-2-span {
		font-size: 30px;
	}
}

.selling-point {
	width: 100%;
	max-width: 900px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	position: relative;
	margin-bottom: 60px;
}

.selling-point.first {
	margin: 80px 0 20px;
}

@media(max-width: 500px) {
	.selling-point {
		flex-direction: column;
	}

	.selling-point.right {
		flex-direction: column-reverse;
	}

	.selling-point.first {
		margin: 30px 0 120px;
	}
}

.copy {
	display: flex;
	flex-direction: column;
	max-width: 500px;
}

.copy > span.selling-point-title {
	color: rgba(34, 3, 79, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: bold;
	font-size: 32px;
	margin-bottom: 6px;
}

.copy > span.selling-point-text {
	color: rgba(34, 3, 79, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: Regular 8;
	line-height: 1.5;

	text-align: left;
	font-size: 18px;
}

.selling-point > .spacer {
	width: 60px;
	min-width: 60px;
	height: 1px;
}

@media(max-width: 500px) {
	.selling-point > .spacer {
		min-height: 30px;
	}

	.copy > span.selling-point-title {
		font-size: 20px;
	}

	.copy > span.selling-point-text {
		font-size: 15px;
	}
}

.landing-page-img {
	height: 500px;
	object-fit: contain;
	border-radius: 20px;
	overflow: hidden;
	box-shadow: 12px 12px 18px rgba(0, 0, 0, 0.10000000149011612);
}

.blob-1 {
	width: 700px;
	height: 500px;
	position: absolute;
	right: -500px;
	opacity: 1;
	z-index: -1;
}

.screenshot-group {
	width: 450px;
	min-width: 450px;
	height: 360px;
	position: relative;
}

.frame-1 {
	height: 292px;
	position: absolute;
	top: 30px;
	left: 0px;
	object-fit: contain;
	box-shadow: 12px 12px 18px rgba(0, 0, 0, 0.10000000149011612);
}

.frame-2 {
	height: 150px;
	opacity: 1;
	position: absolute;
	top: 0px;
	right: 0px;
	object-fit: contain;
	box-shadow: 0px 8px 18px rgba(0, 0, 0, 0.10000000149011612);
	overflow: hidden;
}

.frame-3 {
	height: 167px;
	border-radius: 10px;
	opacity: 1;
	position: absolute;
	bottom: 0px;
	right: 0px;
	object-fit: contain;
	box-shadow: 0px 8px 21px rgba(0, 0, 0, 0.10000000149011612);
	overflow: hidden;
}

@media(max-width: 500px) {
	.screenshot-group {
		width: 100%;
		min-width: 100%;
	}
}


.price-circle {
	height: 200px;
	width: 200px;
	border-radius: 200px;
	display: flex;
	flex-direction: column;
	font-weight: bolder;
	align-items: center;
	justify-content: center;
	background: linear-gradient(rgba(255, 208, 55, 0.4), rgba(249, 180, 167, 0.6));
}

.price-circle > span {
	color: rgba(60, 63, 183, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: Regular 6;
	font-size: 22px;
	text-align: center;
}

.price-circle > span.price {
	font-size: 32px;
}


.section-2-wrapper > .end-cap {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.end-cap-text {
	color: rgba(34, 3, 79, 1);
	font-family: HouschkaRoundedAlt;
	font-weight: Regular 8;

	text-align: center;
	font-size: 18px;
	margin-bottom: 20px;
}



.blob-2 {
	width: 100%;
	min-width: 600px;
	height: 500px;
	position: absolute;
	left: 0;
	bottom: 0;
	opacity: 1;
	z-index: -1;
}

.section-3-wrapper {
	width: 100%;
	opacity: 1;
	margin-top: 180px;

  display: flex;
  flex-direction: column;
	align-items: center;
}

.email-container {
	width: 100%;
	max-width: 400px;
	background-color: #3D3FB7;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	padding: 48px 48px;
}

.email-container > span {
	margin: 8px;
	color: #FFFFFF;
	font-family: HouschkaRoundedAlt;
	font-weight: Regular 8;

	text-align: center;
	font-size: 16px;
}

.email-container > span > b {
	font-size: 20px;
}
